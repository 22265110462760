import React from 'react'
import { Alert } from '@mui/material'

const AlertBox = (props) => {
  const { type, message } = props;

  const getSeverity = (type) => {
    if (type === 'notice') return 'success';
    if (type === 'alert') return 'error';
    return 'info';
  }
  
  const severity = getSeverity(type);


  return (
    <Alert severity={severity}>
      { message }
    </Alert>
  )
}

export default AlertBox