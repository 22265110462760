import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchDriverByCountryRetention = createAsyncThunk(
  'driverByCountry/fetchRetention',
  async () => {
    try {
      const response = await axios.get('/cohorts/retention_picap_driver_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

const fetchDriverByCountryGMVData = createAsyncThunk(
  'driverByCountry/fetchGMV',
  async () => {
    try {
      const response = await axios.get('/cohorts/gmv_picap_driver_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

const fetchDriverByCountryUnique = createAsyncThunk(
  'driverByCountry/fetchUnique',
  async () => {
    try {
      const response = await axios.get('/cohorts/unique_picap_driver_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

export { fetchDriverByCountryRetention, fetchDriverByCountryGMVData, fetchDriverByCountryUnique }
