import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import HeatMapChart from "./HeatMapChart";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import DatePicker from "./DatePicker";
import RefreshIcon from '@mui/icons-material/Refresh';
import CohortDialogGraph from "./CohortDialogGraph";

class CohortDialog extends React.Component {
    constructor(props) {
        super(props);
        const { data } = this.props;
        this.state = {
            month: '',
            data: data ? data : []
        };
    }

    componentDidMount() {
        // this.filterTransactions(1);
    }

    filterTransactions(page) {
        $.ajax({
            url: './gmv_picap_passenger_all_by_cohort.js',
            data: {
                page: page
            },
            success: (data) => {
                this.setState({ data: data });
            }
        })

    }

    onMonth(event) {
        this.setState({ month: event.target.value }, () => {
            this.filterTransactions(1);
        });
    }

    render() {
        const { open, handleClose, title, fillRGB } = this.props;
        const { month, data } = this.state;
        return (
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    scroll="paper"
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                    fullWidth={true}
                    maxWidth="xl"
                >
                    <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
                    <DialogContent dividers={true}>
                        <Grid container spacing={2} className="dialog_container">
                            <Grid item xs={12} sm={12} md={12} className="dialog_actions_container">
                                <Stack direction="row" spacing={2}>
                                    <DatePicker onMonth={this.onMonth.bind(this)} month={month} />
                                </Stack>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} className="dialog_graph_container">
                                <CohortDialogGraph data={data} fillRGB={fillRGB} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>CERRAR</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default CohortDialog;
