import React from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
// import axios from 'axios';

const Navbar = (props) => {
  const { logout } = props;
  
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <div dangerouslySetInnerHTML={{__html: logout}} />
          </Typography>
          <Button color="inherit" />
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default Navbar