import React from "react"
import Grid from '@mui/material/Grid';

class CohortRow extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    render() {
        const { children, title, icon } = this.props;
        return (
            <Grid container spacing={2} className="container">
                <Grid item xs={12} sm={12} md={12} className="cohort_grid_item_title">
                    {icon()} {title}
                </Grid>
                {children}
            </Grid>
        )
    }
}

export default CohortRow;
