import React from "react"
import Grid from '@mui/material/Grid';
import CohortItem from "./CohortItem";
import CohortRow from "./CohortRow";
import MexicoFlag from 'images/mx.png'
import DeliveryBike from 'images/delivery_bike.png'

class CohortPiboxMexico extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { openDialog } = this.props;
        return (
            <CohortRow
                title="Pibox logistics Mexico"
                icon={() => {
                    return (
                        <span>
                            <img src={DeliveryBike} className="row_icon" /> <img src={MexicoFlag} className="flag" />
                        </span>
                    )
                }}
            >
                <Grid item xs={12} sm={6} md={4} >
                    <div className="cohort_grid_item_content">
                        <CohortItem
                            graphHeight={250}
                            graphWidth="100%"
                            title="Retention by Cohort"
                            openDialog={openDialog}
                            category="logisticsByCountry"
                            dataset="retention"
                            countryFilter="Mexico"
                            graphType="Cohort"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                    <div className="cohort_grid_item_content">
                        <CohortItem
                            graphHeight={250}
                            graphWidth="100%"
                            title="GMV by Cohort"
                            openDialog={openDialog}
                            category="logisticsByCountry"
                            dataset="gmv"
                            countryFilter="Mexico"
                            graphType="Cohort"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                    <div className="cohort_grid_item_content">
                        <CohortItem
                            graphHeight={250}
                            graphWidth="100%"
                            title="Uniq company by Cohort"
                            openDialog={openDialog}
                            category="logisticsByCountry"
                            dataset="unique"
                            countryFilter="Mexico"
                            graphType="Cohort"
                        />
                    </div>
                </Grid>
            </CohortRow>
        )
    }
}

export default CohortPiboxMexico;
