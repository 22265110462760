import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchCocoaTractionRetention = createAsyncThunk(
  'cocoa/fetchRetention',
  async () => {
    try {
      const response = await axios.get('/cohorts/retention_cocoa_traction_all_by_cohort.js');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

export { fetchCocoaTractionRetention }
