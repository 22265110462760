import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchPiproGMVData = createAsyncThunk(
    'pipro/fetchGmv',
    async () => {
        try {
            const response = await axios.get('cohorts/gmv_pipro_all_by_cohort.js');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproRetention = createAsyncThunk(
    'pipro/fetchRetention',
    async () => {
        try {
            const response = await axios.get('cohorts/retention_pipro_all_by_cohort.js');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproUnique = createAsyncThunk(
    'pipro/fetchUnique',
    async () => {
        try {
            const response = await axios.get('cohorts/unique_pipro_all_by_cohort.js');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)


export { fetchPiproGMVData, fetchPiproRetention, fetchPiproUnique }
