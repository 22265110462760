import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchPiproWeekGMVData = createAsyncThunk(
    'piproWeek/fetchGmv',
    async () => {
        try {
            const response = await axios.get('cohorts/gmv_pipro_week_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproWeekRetention = createAsyncThunk(
    'piproWeek/fetchRetention',
    async () => {
        try {
            const response = await axios.get('cohorts/retention_pipro_week_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproWeekUnique = createAsyncThunk(
    'piproWeek/fetchUnique',
    async () => {
        try {
            const response = await axios.get('cohorts/unique_pipro_week_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

export { fetchPiproWeekGMVData, fetchPiproWeekRetention, fetchPiproWeekUnique }
