import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchPiproBiweekGMVData = createAsyncThunk(
    'piproBiweek/fetchGmv',
    async () => {
        try {
            const response = await axios.get('cohorts/gmv_pipro_biweek_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproBiweekRetention = createAsyncThunk(
    'piproBiweek/fetchRetention',
    async () => {
        try {
            const response = await axios.get('cohorts/retention_pipro_biweek_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproBiweekUnique = createAsyncThunk(
    'piproBiweek/fetchUnique',
    async () => {
        try {
            const response = await axios.get('cohorts/unique_pipro_biweek_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

export { fetchPiproBiweekGMVData, fetchPiproBiweekRetention, fetchPiproBiweekUnique }
