import React, { useEffect, useState } from "react"
import Grid from '@mui/material/Grid';
import CohortItem from "./CohortItem";
import CohortRow from "./CohortRow";
import MexicoFlag from 'images/mx.png'
import DeliveryBike from 'images/delivery_bike.png'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const CohortByCountry = (props) => {
  const { openDialog } = props;
  const [ category, setCategory ] = useState('logisticsByCountry');
  const [ country, setCountry ] = useState('Colombia');
  const [ title, setTitle ] = useState('Pibox Logistics');
  const [ uniqueTag, setUniqueTag ] = useState('Companies');

  // Add more flags and countries if necessary
  const countryOptions = [
    {
      name: 'Colombia',
      flag: '🇨🇴',
    },
    {
      name: 'Argentina',
      flag: '🇦🇷',
    },
    {
      name: 'Brazil',
      flag: '🇧🇷',
    },
    {
      name: 'Chile',
      flag: '🇨🇱',
    },
    {
      name: 'Ecuador',
      flag: '🇪🇨',
    },
    {
      name: 'El Salvador',
      flag: '🇸🇻',
    },
    {
      name: 'Guatemala',
      flag: '🇬🇹',
    },
    {
      name: 'Mexico',
      flag: '🇲🇽',
    },
    {
      name: 'Nicaragua',
      flag: '🇳🇮',
    },
    {
      name: 'Paraguay',
      flag: '🇵🇾',
    },
    {
      name: 'Perú',
      flag: '🇵🇪',
    },
    {
      name: 'Uruguay',
      flag: '🇺🇾',
    },
    {
      name: 'Venezuela',
      flag: '🇻🇪',
    },
  ];

  const categoryNames = {
    logisticsByCountry: 'Pibox Logistics',
    driverByCountry: 'Picap General',
    mototaxiByCountry: 'Picap Mototaxi'
  };
  
  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleCountry = (e) => {
    setCountry(e.target.value);
  };

  useEffect(() => {
    const flag = countryOptions.find((countryOption) => countryOption.name === country).flag;
    setTitle(`${categoryNames[category]} ${country} ${flag} - ${uniqueTag}`);
  }, [category, country, uniqueTag]);

  useEffect(() => {
    const tags = {
      logisticsByCountry: 'Companies',
      driverByCountry: 'Drivers',
      mototaxiByCountry: 'Passengers'
    };
    setUniqueTag(tags[category]);
  }, [category]);

  return (
    <CohortRow
      title={title}
      icon={() => {
        return (
          <span>
            <img src={DeliveryBike} className="row_icon" />
          </span>
        )
      }}
    >
      <Grid item xs={6} >
        <div>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="simple-select-autowidth-label">Category</InputLabel>
            <Select
              labelId="simple-select-autowidth-label"
              id="simple-select-autowidth"
              value={category}
              onChange={handleCategory}
              autoWidth
              label="All months"
              className="month-selector"
            >
              {
                Object.entries(categoryNames).map(([option, name], index) => (
                  <MenuItem key={index} value={option}>{name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={6} >
        <div>
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="simple-select-autowidth-label">Country</InputLabel>
            <Select
              labelId="simple-select-autowidth-label"
              id="simple-select-autowidth"
              value={country}
              onChange={handleCountry}
              autoWidth
              label="All months"
              className="month-selector"
            >
              {
                countryOptions.map((countryOption, index) => (
                  <MenuItem key={index} value={countryOption.name}>{countryOption.flag} {countryOption.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} >
        <div className="cohort_grid_item_content">
          <CohortItem
            graphHeight={250}
            graphWidth="100%"
            title="Retention by Cohort"
            openDialog={openDialog}
            category={category}
            dataset="retention"
            countryFilter={country}
            graphType="Cohort"
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} >
        <div className="cohort_grid_item_content">
          <CohortItem
            graphHeight={250}
            graphWidth="100%"
            title="GMV by Cohort"
            openDialog={openDialog}
            category={category}
            dataset="gmv"
            countryFilter={country}
            graphType="Cohort"
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6} md={4} >
        <div className="cohort_grid_item_content">
          <CohortItem
            graphHeight={250}
            graphWidth="100%"
            title={`Uniq ${uniqueTag} by Cohort`}
            openDialog={openDialog}
            category={category}
            dataset="unique"
            countryFilter={country}
            graphType="Cohort"
          />
        </div>
      </Grid>
    </CohortRow>
  )
} 

export default CohortByCountry;
