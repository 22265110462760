import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import mototaxiReducer from './mototaxi/slice'
import logisticsReducer from './logistics/slice'
import driverReducer from './driver/slice'
import cocoaReducer from './cocoa/slice'
import logisticsByCountryReducer from './logisticsByCountry/slice'
import mototaxiByCountryReducer from './mototaxiByCountry/slice'
import driverByCountryReducer from './driverByCountry/slice'
import piprimeReducer from './piprime/slice'
import piproReducer from './pipro/slice'
import piproWeekReducer from './piproWeek/slice'
import piproBiweekReducer from './piproBiweek/slice'
import piproMonthReducer from './piproMonth/slice'
import piproQuarterReducer from './piproQuarter/slice'

import { fetchMototaxiGMVData, fetchMototaxiRetention, fetchMototaxiUnique } from "./mototaxi/actions";
import { fetchLogisticsGMVData, fetchLogisticsRetention, fetchLogisticsUnique } from "./logistics/actions";
import { fetchDriverGMVData, fetchDriverRetention, fetchDriverUnique } from "./driver/actions";
import { fetchCocoaTractionRetention } from "./cocoa/actions";
import { fetchLogisticsByCountryGMVData, fetchLogisticsByCountryRetention, fetchLogisticsByCountryUnique } from "./logisticsByCountry/actions";
import { fetchMototaxiByCountryGMVData, fetchMototaxiByCountryRetention, fetchMototaxiByCountryUnique } from "./mototaxiByCountry/actions";
import { fetchDriverByCountryGMVData, fetchDriverByCountryRetention, fetchDriverByCountryUnique } from "./driverByCountry/actions";
import { fetchPiPrimeGMVData, fetchPiPrimeRetention, fetchPiPrimeUnique } from "./piprime/actions";
import { fetchPiproGMVData, fetchPiproRetention, fetchPiproUnique } from "./pipro/actions";
import { fetchPiproWeekGMVData, fetchPiproWeekRetention, fetchPiproWeekUnique } from "./piproWeek/actions";
import { fetchPiproBiweekGMVData, fetchPiproBiweekRetention, fetchPiproBiweekUnique } from "./piproBiweek/actions";
import { fetchPiproMonthGMVData, fetchPiproMonthRetention, fetchPiproMonthUnique } from "./piproMonth/actions";
import { fetchPiproQuarterGMVData, fetchPiproQuarterRetention, fetchPiproQuarterUnique } from "./piproQuarter/actions";

const store = configureStore({
  reducer: {
    mototaxi: mototaxiReducer,
    logistics: logisticsReducer,
    driver: driverReducer,
    cocoa: cocoaReducer,
    logisticsByCountry: logisticsByCountryReducer,
    mototaxiByCountry: mototaxiByCountryReducer,
    driverByCountry: driverByCountryReducer,
    piprime: piprimeReducer,
    pipro: piproReducer,
    piproWeek: piproWeekReducer,
    piproBiweek: piproBiweekReducer,
    piproMonth: piproMonthReducer,
    piproQuarter: piproQuarterReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger), 
});

store.dispatch(fetchMototaxiByCountryRetention());
store.dispatch(fetchMototaxiByCountryGMVData());
store.dispatch(fetchMototaxiByCountryUnique());

store.dispatch(fetchLogisticsByCountryRetention());
store.dispatch(fetchLogisticsByCountryGMVData());
store.dispatch(fetchLogisticsByCountryUnique());

store.dispatch(fetchDriverByCountryRetention());
store.dispatch(fetchDriverByCountryGMVData());
store.dispatch(fetchDriverByCountryUnique());

store.dispatch(fetchMototaxiRetention());
store.dispatch(fetchMototaxiGMVData());
store.dispatch(fetchMototaxiUnique());

store.dispatch(fetchLogisticsRetention());
store.dispatch(fetchLogisticsGMVData());
store.dispatch(fetchLogisticsUnique());

store.dispatch(fetchDriverRetention());
store.dispatch(fetchDriverGMVData());
store.dispatch(fetchDriverUnique());

store.dispatch(fetchCocoaTractionRetention());

store.dispatch(fetchPiPrimeRetention());
store.dispatch(fetchPiPrimeGMVData());
store.dispatch(fetchPiPrimeUnique());

store.dispatch(fetchPiproRetention());
store.dispatch(fetchPiproGMVData());
store.dispatch(fetchPiproUnique());

store.dispatch(fetchPiproWeekRetention());
store.dispatch(fetchPiproWeekGMVData());
store.dispatch(fetchPiproWeekUnique());

store.dispatch(fetchPiproBiweekRetention());
store.dispatch(fetchPiproBiweekGMVData());
store.dispatch(fetchPiproBiweekUnique());

store.dispatch(fetchPiproMonthRetention());
store.dispatch(fetchPiproMonthGMVData());
store.dispatch(fetchPiproMonthUnique());

store.dispatch(fetchPiproQuarterRetention());
store.dispatch(fetchPiproQuarterGMVData());
store.dispatch(fetchPiproQuarterUnique());
export default store;
