import React from "react"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import HeatMapChart from "./HeatMapChart";
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import RefreshIcon from '@mui/icons-material/Refresh';

class CohortDialogGraph extends React.Component {
    constructor(props) {
        super(props);
        this.svgRef = React.createRef();
    }

    componentDidMount() {
        this.chart = HeatMapChart(this.svgRef, {
            gridSize : 30, animate: false
        });
        const { data, fillRGB } = this.props;
        if(data) { this.chart.update(data, fillRGB) }
    }

    componentDidUpdate(nextProps) {
        const { data, fillRGB } = nextProps;
        if (data) { this.chart.update(data, fillRGB) }
    }

    render() {
        return (
            <svg ref={this.svgRef} />
        )
    }
}

export default CohortDialogGraph;
