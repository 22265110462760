import { createSlice } from "@reduxjs/toolkit";
import { fetchPiPrimeGMVData, fetchPiPrimeRetention, fetchPiPrimeUnique } from "../piprime/actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

const piprimeSlice = createSlice({
  name: 'piprime',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPiPrimeRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiPrimeRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiPrimeRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiPrimeGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiPrimeGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiPrimeGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiPrimeUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiPrimeUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiPrimeUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default piprimeSlice.reducer;
