import * as d3 from "d3";

const MultilineChart = (svgRef, {
    x = ([x]) => x,
    y = ([, y]) => y,
    z = () => 1,
    dotTitle, yLabel,
    width, height
} = {}) => {
    const curve = d3.curveLinear;
    const marginTop = 20;
    const marginRight = 30;
    const marginBottom = 30;
    const marginLeft = 40;
    const yRange = [height - marginBottom, marginTop];
    const xRange = [marginLeft, width - marginRight];
    const xType = d3.scaleUtc;
    const yType = d3.scaleLinear;
    const color = "steelblue";
    const strokeLinecap = null;
    const strokeLinejoin = null;
    const strokeOpacity = null;
    const strokeWidth = 1.5;
    const mixBlendMode = "multiply";
    const svgEl = d3.select(svgRef.current);
    const svg = svgEl.attr("width", width)
        .attr("height", height)
        .attr("class", "chart")
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
        .style("-webkit-tap-highlight-color", "transparent")
        .on("touchstart", event => event.preventDefault());

    const path = svg.append("g")
        .attr("fill", "none")
        .attr("stroke", typeof color === "string" ? color : null)
        .attr("stroke-linecap", strokeLinecap)
        .attr("stroke-linejoin", strokeLinejoin)
        .attr("stroke-width", strokeWidth)
        .attr("stroke-opacity", strokeOpacity)

    const dot = svg.append("g")
        .attr("display", "none");

    dot.append("circle")
        .attr("r", 2.5);

    dot.append("text")
        .attr("font-family", "sans-serif")
        .attr("font-size", 10)
        .attr("text-anchor", "middle")
        .attr("y", -8);

    const update = (data) => {
        const dataX = d3.map(data, x);
        const dataY = d3.map(data, y);
        const dataZ = d3.map(data, z);
        const dataO = d3.map(data, d => d);
        const defined = (d, i) => !isNaN(dataX[i]) && !isNaN(dataY[i]);
        const dataD = d3.map(data, defined);

        // Compute default domains, and unique the z-domain.
        const graphXDomain = d3.extent(dataX);
        const graphYDomain = [0, d3.max(dataY)];
        var graphZDomain = dataZ;
        graphZDomain = new d3.InternSet(graphZDomain);

        // Construct scales and axes.
        const graphXScale = xType(graphXDomain, xRange);
        const graphYScale = yType(graphYDomain, yRange);
        const graphXAxis = d3.axisBottom(graphXScale).ticks(width / 80).tickSizeOuter(0);
        const graphYAxis = d3.axisLeft(graphYScale).ticks(height / 60);

        const dataI = d3.range(dataX.length).filter(i => graphZDomain.has(dataZ[i]));

        // Compute titles.
        const dataT = dotTitle === undefined ? dataZ : dotTitle === null ? null : d3.map(data, dotTitle);

        // Construct a line generator.
        const line = d3.line()
            .defined(i => dataD[i])
            .curve(curve)
            .x(i => graphXScale(dataX[i]))
            .y(i => graphYScale(dataY[i]));

        const xAxis = svgEl.select(".xAxis").size() > 0 ? svgEl.select(".xAxis") : svgEl.append("g").attr("class", "xAxis");

        xAxis.attr("transform", `translate(0,${height - marginBottom})`)
            .call(graphXAxis);

        const yAxis = svgEl.select(".yAxis").size() > 0 ? svgEl.select(".yAxis") : svgEl.append("g").attr("class", "yAxis");

        yAxis.attr("transform", `translate(${marginLeft},0)`)
            .call(graphYAxis)
        //.call(g => g.select(".domain").remove())

        yAxis.selectAll(".lines_clone").remove();
        yAxis.selectAll(".tick line")
            .clone()
            .attr("class", "lines_clone")
            .attr("x2", width - marginLeft - marginRight)
            .attr("stroke-opacity", 0.1)

        if (yAxis.select(".chart_title").size() == 0) {
            yAxis.append("text")
                .attr("class", "chart_title")
                .attr("x", -marginLeft)
                .attr("y", 10)
                .attr("fill", "currentColor")
                .attr("text-anchor", "start")
                .text(yLabel);
        }

        const paths = path.selectAll("path")
            .data(d3.group(dataI, i => dataZ[i]))
            .join("path")
            .style("mix-blend-mode", mixBlendMode)
            .attr("stroke", typeof color === "function" ? ([z]) => color(z) : null)
            .attr("d", ([, dData]) => line(dData));

        const pointermoved = (event) => {
            const [xm, ym] = d3.pointer(event);
            const i = d3.least(dataI, i => Math.hypot(graphXScale(dataX[i]) - xm, graphYScale(dataY[i]) - ym));
            paths.style("stroke", ([z]) => dataZ[i] === z ? null : "#ddd").filter(([z]) => dataZ[i] === z).raise();
            dot.attr("transform", `translate(${graphXScale(dataX[i])},${graphYScale(dataY[i])})`);
            if (dataT) dot.select("text").text(dataT[i]);
            svg.property("value", dataO[i]).dispatch("input", { bubbles: true });
        }

        const pointerentered = () => {
            paths.style("mix-blend-mode", null).style("stroke", "#ddd");
            dot.attr("display", null);
        }

        const pointerleft = () => {
            paths.style("mix-blend-mode", mixBlendMode).style("stroke", null);
            dot.attr("display", "none");
            svg.node().value = null;
            svg.dispatch("input", { bubbles: true });
        }

        svgEl.on("pointerenter", pointerentered)
            .on("pointermove", pointermoved)
            .on("pointerleave", pointerleft)
    }
    let chartFinal = Object.assign(svg.node(), { value: null });
    chartFinal.update = update;
    return chartFinal;
}

export default MultilineChart;