import * as React from 'react';
import TextField from '@mui/material/TextField';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function DatePicker({ onMonth, month }) {
    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="simple-select-autowidth-label">All months</InputLabel>
                <Select
                    labelId="simple-select-autowidth-label"
                    id="simple-select-autowidth"
                    value={month}
                    onChange={onMonth}
                    autoWidth
                    label="All months"
                    className="month-selector"
                >
                    <MenuItem value={''}>All months</MenuItem>
                    <MenuItem value={'01-01-2022'}>Enero 2022</MenuItem>
                    <MenuItem value={'01-02-2022'}>Febrero 2022</MenuItem>
                    <MenuItem value={'01-03-2022'}>Marzo 2022</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}