import React from "react";
import { Provider } from "react-redux";
import store from "../redux/store";
import Cohorts from "./Cohorts";

const App = () => {
  return (
    <Provider store={store}>
      <Cohorts />
    </Provider>
  )
}

export default App
