import { createSlice } from "@reduxjs/toolkit";
import { fetchPiproMonthRetention, fetchPiproMonthGMVData, fetchPiproMonthUnique } from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

// pipro general
const piproMonthSlicer = createSlice({
  name: 'piproMonth',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPiproMonthGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproMonthGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproMonthGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproMonthRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproMonthRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproMonthRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproMonthUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproMonthUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproMonthUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default piproMonthSlicer.reducer;