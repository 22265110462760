import React from "react"
import data from "./SCHC.json";
import MultilineChart from "./MultilineChart";

export default function LineExample() {
  const svgRef = React.useRef(null);

  React.useEffect(() => {
    const chart = MultilineChart(svgRef, {
      x: d => new Date(d.date),
      y: d => d.unemployment,
      z: d => d.division,
      height: 600,
      width: 1000
    });
    chart.update(data);
  });

  return (
    <div className="App">
      <svg ref={svgRef} />
    </div>
  );
}