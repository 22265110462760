import React from "react"
import Grid from '@mui/material/Grid';
import CohortItem from "./CohortItem";
import CohortRow from "./CohortRow";
import TransportBike from 'images/transport_bike.png'

class CohortPiprime extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        const { openDialog } = this.props;
        return (
            <CohortRow
                title="Picash General Piprime"
                icon={() => (
                    <img src={TransportBike} className="row_icon" />
                )}
            >
                <Grid item xs={12} sm={6} md={4} >
                    <div className="cohort_grid_item_content">
                        <CohortItem
                            graphHeight={250}
                            graphWidth="100%"
                            title="Retention by Cohort"
                            openDialog={openDialog}
                            category="piprime"
                            dataset="retention"
                            graphType="Cohort"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                    <div className="cohort_grid_item_content">
                        <CohortItem
                            graphHeight={250}
                            graphWidth="100%"
                            title="GMV by Cohort"
                            openDialog={openDialog}
                            category="piprime"
                            dataset="gmv"
                            graphType="Cohort"
                        />
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} md={4} >
                    <div className="cohort_grid_item_content">
                        <CohortItem
                            graphHeight={250}
                            graphWidth="100%"
                            title="Unique by Cohort"
                            openDialog={openDialog}
                            category="piprime"
                            dataset="unique"
                            graphType="Cohort"
                        />
                    </div>
                </Grid>
            </CohortRow>
        )
    }
}

export default CohortPiprime;
