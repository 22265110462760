import React from "react"
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import CohortTransport from "./CohortTransport";
import CohortPibox from "./CohortPibox";
import CohortPiboxMexico from "./CohortPiboxMexico";
import CohortPiboxColombia from "./CohortPiboxColombia";
import CohortDialog from "./CohortDialog";
import { ThreeSixty } from "@mui/icons-material";
import CohortDriver from "./CohortDriver";
import CohortCocoa from "./CohortCocoa";
import CohortByCountry from "./CohortByCountry";
import CohortPiprime from "./CohortPiprime";
import CohortPipro from "./CohortPipro";
import CohortPiproWeek from "./CohortPiproWeek";
import CohortPiproBiweek from "./CohortPiproBiweek";
import CohortPiproMonth from "./CohortPiproMonth";
import CohortPiproQuarter from "./CohortPiproQuarter";

class Cohorts extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dialogOpen: false,
            dialogData: null,
            dialogTitle: null,
            dialogFillRGB: null,
        }
    }

    componentDidMount() {
    }

    openDialog(title, data, fillRGB) {
        this.setState({
            dialogTitle: title,
            dialogData: data,
            dialogFillRGB: fillRGB,
        }, () => {
            this.setState({
                dialogOpen: true
            });
        });
    }

    closeDiaglog() {
        this.setState({
            dialogTitle: null, 
            dialogOpen: false,
            dialogData: null,
            dialogFillRGB: null,
        });
    }

    render() {
        const { dialogOpen, dialogTitle, dialogData, dialogFillRGB } = this.state;
        return (
            <Grid container spacing={2} className="container">
                <Grid item md={12}>
                    <CohortTransport openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortPibox openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortDriver openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortCocoa openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortByCountry openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortPiprime openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortPipro openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortPiproWeek openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortPiproBiweek openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortPiproMonth openDialog={this.openDialog.bind(this)} />
                </Grid>
                <Grid item md={12}>
                    <CohortPiproQuarter openDialog={this.openDialog.bind(this)} />
                </Grid>
                
                {
                    dialogData ? (
                        <CohortDialog
                            title={dialogTitle}
                            data={dialogData}
                            open={dialogOpen}
                            fillRGB={dialogFillRGB}
                            handleClose={this.closeDiaglog.bind(this)}
                        />
                    ) : null
                }
            </Grid>
        )
    }
}

export default Cohorts;
