import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchLogisticsByCountryRetention = createAsyncThunk(
  'logisticsByCountry/fetchRetention',
  async () => {
    try {
      const response = await axios.get('/cohorts/retention_pibox_freight_b2b2c_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

const fetchLogisticsByCountryGMVData = createAsyncThunk(
  'logisticsByCountry/fetchGMV',
  async () => {
    try {
      const response = await axios.get('/cohorts/gmv_pibox_freight_b2b2c_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

const fetchLogisticsByCountryUnique = createAsyncThunk(
  'logisticsByCountry/fetchUnique',
  async () => {
    try {
      const response = await axios.get('/cohorts/unique_pibox_freight_b2b2c_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)



export { fetchLogisticsByCountryRetention, fetchLogisticsByCountryGMVData, fetchLogisticsByCountryUnique }