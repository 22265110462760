import React, { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux";
import HeatMapChart from "./HeatMapChart";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import CohortDialog from "./CohortDialog";
import { element } from "prop-types";

const CohortItem = (props) => {
  const { 
    gridSize, 
    graphHeight, 
    graphWidth,
    title,
    openDialog,
    category,
    countryFilter,
    dataset,
    fillRGB
  } = props;

  const data = useSelector((state) => state[category][dataset]);
  const [filteredData, setFilteredData] = useState(data);

  const svgRef = useRef(null);

  useEffect(() => {
    if (countryFilter) {
      setFilteredData(data.filter((element) => (element.country === countryFilter)))
    } else {
      setFilteredData(data);
    }
  }, [data, countryFilter]);

  useEffect(() => {
    const chart = HeatMapChart(svgRef, {
      gridSize: gridSize ? gridSize : 30,
      height: graphHeight,
      width: graphWidth,
      animate: true,
    });
    chart.update(filteredData, fillRGB);
  }, [
    filteredData,
    gridSize,
    graphHeight,
    graphWidth,
    fillRGB,
  ]);

  return (
    <Container className="cohort_item_container">
      <Grid container spacing={2} className="cohort_item">
        <Grid item xs={8} className="cohort_item_title">
          {title}
        </Grid>
        <Grid item xs={4} className="see_more_button">
          <Button
            variant="text"
            color="secondary"
            disabled={data == null || data == undefined || data.length <= 0}
            onClick={() => {openDialog(title, filteredData, fillRGB)}}
          >
            Ver mas
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
            <svg ref={svgRef} />
        </Grid>
      </Grid>
    </Container>
  )
}

export default CohortItem;