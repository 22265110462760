import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchPiproQuarterGMVData = createAsyncThunk(
    'piproQuarter/fetchGmv',
    async () => {
        try {
            const response = await axios.get('cohorts/gmv_pipro_quarter_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproQuarterRetention = createAsyncThunk(
    'piproQuarter/fetchRetention',
    async () => {
        try {
            const response = await axios.get('cohorts/retention_pipro_quarter_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproQuarterUnique = createAsyncThunk(
    'piproQuarter/fetchUnique',
    async () => {
        try {
            const response = await axios.get('cohorts/unique_pipro_quarter_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

export { fetchPiproQuarterGMVData, fetchPiproQuarterRetention, fetchPiproQuarterUnique }
