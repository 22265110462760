import { createSlice } from "@reduxjs/toolkit";
import { 
  fetchDriverRetention,
  fetchDriverGMVData,
  fetchDriverUnique
} from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

const driversSlice = createSlice({
  name: 'driver',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchDriverRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchDriverRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchDriverRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchDriverGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchDriverGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchDriverGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchDriverUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchDriverUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchDriverUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default driversSlice.reducer;
