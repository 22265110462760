import { createSlice } from "@reduxjs/toolkit";
import { fetchPiproRetention, fetchPiproGMVData, fetchPiproUnique } from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

// pipro general
const piproSlicer = createSlice({
  name: 'pipro',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPiproGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default piproSlicer.reducer;