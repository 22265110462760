import { createSlice } from "@reduxjs/toolkit";
import { fetchMototaxiRetention, fetchMototaxiGMVData, fetchMototaxiUnique } from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

const mototaxiSlice = createSlice({
  name: 'mototaxi',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMototaxiRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchMototaxiRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchMototaxiRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchMototaxiGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchMototaxiGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchMototaxiGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchMototaxiUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchMototaxiUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchMototaxiUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default mototaxiSlice.reducer;
