import { createSlice } from "@reduxjs/toolkit";
import {
  fetchPiproWeekGMVData, fetchPiproWeekRetention, fetchPiproWeekUnique
} from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

const piproWeekSlice = createSlice({
  name: 'piproWeek',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPiproWeekGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproWeekGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproWeekGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproWeekRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproWeekRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproWeekRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproWeekUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproWeekUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproWeekUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default piproWeekSlice.reducer;
