import { createSlice } from "@reduxjs/toolkit";
import { fetchMototaxiByCountryRetention, fetchMototaxiByCountryGMVData, fetchMototaxiByCountryUnique } from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

const mototaxiByCountrySlice = createSlice({
  name: 'mototaxiByCountry',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMototaxiByCountryRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchMototaxiByCountryRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchMototaxiByCountryRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchMototaxiByCountryGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchMototaxiByCountryGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchMototaxiByCountryGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchMototaxiByCountryUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchMototaxiByCountryUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchMototaxiByCountryUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default mototaxiByCountrySlice.reducer;
