import { createSlice } from "@reduxjs/toolkit";
import { fetchPiproQuarterRetention, fetchPiproQuarterGMVData, fetchPiproQuarterUnique } from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

// pipro general
const piproQuarterSlicer = createSlice({
  name: 'piproQuarter',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPiproQuarterGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproQuarterGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproQuarterGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproQuarterRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproQuarterRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproQuarterRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproQuarterUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproQuarterUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproQuarterUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default piproQuarterSlicer.reducer;