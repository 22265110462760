import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchPiproMonthGMVData = createAsyncThunk(
    'piproMonth/fetchGmv',
    async () => {
        try {
            const response = await axios.get('cohorts/gmv_pipro_month_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproMonthRetention = createAsyncThunk(
    'piproMonth/fetchRetention',
    async () => {
        try {
            const response = await axios.get('cohorts/retention_pipro_month_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiproMonthUnique = createAsyncThunk(
    'piproMonth/fetchUnique',
    async () => {
        try {
            const response = await axios.get('cohorts/unique_pipro_month_by_cohort');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

export { fetchPiproMonthGMVData, fetchPiproMonthRetention, fetchPiproMonthUnique }
