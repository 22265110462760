import { createSlice } from "@reduxjs/toolkit";
import { fetchLogisticsRetention, fetchLogisticsGMVData, fetchLogisticsUnique } from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

const logisticsSlice = createSlice({
  name: 'logistics',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchLogisticsRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchLogisticsRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchLogisticsRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchLogisticsGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchLogisticsGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchLogisticsGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchLogisticsUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchLogisticsUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchLogisticsUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default logisticsSlice.reducer;
