import { createSlice } from "@reduxjs/toolkit";
import { fetchPiproBiweekRetention, fetchPiproBiweekGMVData, fetchPiproBiweekUnique } from "./actions";

const initialState = {
  retention: [],
  gmv: [],
  unique: [],
  loading: 'idle',
  error: '',
};

// pipro general
const piproBiweekSlicer = createSlice({
  name: 'piproBiweek',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchPiproBiweekGMVData.fulfilled, (state, action) => {
      state.gmv = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproBiweekGMVData.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproBiweekGMVData.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproBiweekRetention.fulfilled, (state, action) => {
      state.retention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproBiweekRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproBiweekRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
    builder.addCase(fetchPiproBiweekUnique.fulfilled, (state, action) => {
      state.unique = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchPiproBiweekUnique.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchPiproBiweekUnique.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default piproBiweekSlicer.reducer;