import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchMototaxiRetention = createAsyncThunk(
  'mototaxi/fetchRetention',
  async () => {
    try {
      const response = await axios.get('/cohorts/retention_picap_passenger_all_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

const fetchMototaxiGMVData = createAsyncThunk(
  'mototaxi/fetchGMV',
  async () => {
    try {
      const response = await axios.get('/cohorts/gmv_picap_passenger_all_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

const fetchMototaxiUnique = createAsyncThunk(
  'mototaxi/fetchUnique',
  async () => {
    try {
      const response = await axios.get('/cohorts/unique_picap_passenger_all_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

export { fetchMototaxiRetention, fetchMototaxiGMVData, fetchMototaxiUnique }