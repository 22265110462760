import { createSlice } from "@reduxjs/toolkit";
import { 
  fetchCocoaTractionRetention,
} from "./actions";

const initialState = {
  tractionRetention: [],
  loading: 'idle',
  error: '',
};

const cocoaSlicer = createSlice({
  name: 'cocoa',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchCocoaTractionRetention.fulfilled, (state, action) => {
      state.tractionRetention = action.payload;
      state.loading = 'idle';
      state.error = '';
    });
    builder.addCase(fetchCocoaTractionRetention.pending, (state) => {
      state.loading = 'pending';
    });
    builder.addCase(fetchCocoaTractionRetention.rejected, (state, action) => {
      state.loading = 'failed';
      state.error = action.payload
    });
  }
});

export default cocoaSlicer.reducer;
