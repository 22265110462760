import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchMototaxiByCountryRetention = createAsyncThunk(
  'mototaxiByCountry/fetchRetention',
  async () => {
    try {
      const response = await axios.get('/cohorts/retention_picap_passenger_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

const fetchMototaxiByCountryGMVData = createAsyncThunk(
  'mototaxiByCountry/fetchGMV',
  async () => {
    try {
      const response = await axios.get('/cohorts/gmv_picap_passenger_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)

const fetchMototaxiByCountryUnique = createAsyncThunk(
  'mototaxiByCountry/fetchUnique',
  async () => {
    try {
      const response = await axios.get('/cohorts/unique_picap_passenger_all_by_country_by_cohort');
      return response.data;
    } catch (err) {
      return err;
    }
  }
)



export { fetchMototaxiByCountryRetention, fetchMototaxiByCountryGMVData, fetchMototaxiByCountryUnique }