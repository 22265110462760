import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const fetchPiPrimeRetention = createAsyncThunk(
    'piprime/fetchRetention',
    async () => {
        try {
            const response = await axios.get('cohorts/retention_piprime_all_by_cohort.js');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiPrimeGMVData = createAsyncThunk(
    'piprime/fetchGmv',
    async () => {
        try {
            const response = await axios.get('cohorts/gmv_piprime_all_by_cohort.js');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

const fetchPiPrimeUnique = createAsyncThunk(
    'piprime/fetchUnique',
    async () => {
        try {
            const response = await axios.get('cohorts/unique_piprime_all_by_cohort.js');
            return response.data;
        } catch (err) {
            return err;
        }
    }
)

export { fetchPiPrimeGMVData, fetchPiPrimeRetention, fetchPiPrimeUnique }
